<template>
  <section class="contact-us-section">
    <div class="container">
      <div
        class="
          row
          align-items-center
          justify-content-center justify-content-xl-end
        "
      >
        <div class="col-md-8 col-lg-5 col-xl-4">
          <h1 class="contact-title">Contact Us</h1>

          <div class="contacts">
            <a
              href="https://www.google.com.ua/maps/place/2620+S+Maryland+Pkwy+14+265,+Las+Vegas,+NV+89109,+%D0%A1%D0%A8%D0%90/@36.1419657,-115.1373778,17z/data=!3m1!4b1!4m5!3m4!1s0x80c8c485fcb07e45:0xa9383dc4585c4307!8m2!3d36.1419614!4d-115.1351891?hl=ru"
              target="_blank"
              class="contacts__item"
            >
              <AppIcon componentName="AddressIcon" />
              <span
                >2620 South Maryland Pkwy, Suite 14 #265 Las Vegas, Nevada
                89109</span
              >
            </a>

            <a href="mailto:support@resume-perfect.com" class="contacts__item">
              <AppIcon componentName="MailIcon" />

              <span>support@resume-perfect.com</span>
            </a>

            <a href="tel:18882641699" class="contacts__item">
              <AppIcon componentName="PhoneIcon" />
              <span> 1-888-264-1699</span>
            </a>
          </div>
        </div>
        <div class="col-md-8 col-lg col-xl-5 offset-xl-2">
          <form
            ref="contactForm"
            action="#"
            class="contact-form"
            @submit.prevent="onSubmitForm"
          >
            <h2 class="contact-form__title">Get in touch</h2>

            <AppField
              type="text"
              label="Enter your name"
              required
              :isInvalid="$v.name.$error"
              @input="setField($event, 'name')"
            >
              <template v-slot:error>
                <p v-if="!$v.name.required">This field is required</p>
              </template>
            </AppField>

            <AppField
              type="name"
              label="Enter your e-mail"
              required
              :isInvalid="$v.email.$error"
              @input="setField($event, 'email')"
            >
              <template v-slot:error>
                <p v-if="!$v.email.required">This field is required</p>
                <p v-if="!$v.email.email">Incorrect email format</p>
              </template>
            </AppField>

            <AppField
              textarea
              label="Your Message"
              required
              :isInvalid="$v.message.$error"
              @input="setField($event, 'message')"
            >
              <template v-slot:error>
                <p v-if="!$v.message.required">This field is required</p>
              </template>
            </AppField>

            <AppButton
              title="Send"
              class="contact-form__btn"
              className="primary"
              type="submit"
              :pending="pending"
            />
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import AppToast from "@/helpers/toast-methods";

export default {
  name: "ContactUs",

  data() {
    return {
      name: "",
      email: "",
      message: "",
      pending: false,
    };
  },

  validations: {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
    message: {
      required,
    },
  },

  methods: {
    setField(fieldData, fieldName) {
      this[fieldName] = fieldData;
    },

    async onSubmitForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      const payload = {
        email: this.email,
        name: this.name,
        text: this.message,
      };

      this.pending = true;

      try {
        await this.$store.dispatch("contact/contactUs", payload);

        this.$refs.contactForm.reset();

        AppToast.toastSuccess("Message was successfully sent");
      } catch (e) {
        console.error(e);
      }

      this.pending = false;
    },
  },
};
</script>

<style lang="scss" scoped>
section.contact-us-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0 95px 0;

  @media (min-width: map_get($grid-breakpoints, xl)) {
    min-height: calc(100vh - 306px);
    padding: 64px 0;
  }
}

.contacts {
  margin-bottom: 54px;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    margin-bottom: 0;
  }

  &__item {
    display: flex;
    font-size: 14px;
    line-height: 150%;
    color: $light-gray;

    @media (min-width: map_get($grid-breakpoints, xl)) {
      font-size: 18px;
      line-height: 140%;
      margin-bottom: 32px;
      color: $body-color;
    }

    svg {
      width: 24px;
      height: 24px;
      margin-right: 15px;
      display: none;

      @media (min-width: map_get($grid-breakpoints, xl)) {
        display: inline-flex;
      }
    }

    span {
      flex: 1;
    }

    &:hover {
      color: $primary;

      svg {
        fill: $primary;
      }
    }
  }
}

.contact-form {
  background-color: #fff;
  padding: 32px 24px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  @media (min-width: map_get($grid-breakpoints, xl)) {
    padding: 32px;
  }

  &__title {
    font-size: 24px;
    margin-bottom: 24px;
  }

  &__btn {
    width: 100%;
  }
}

.contact-title {
  margin-bottom: 16px;

  @media (min-width: map_get($grid-breakpoints, xl)) {
    margin-bottom: 24px;
  }
}
</style>
